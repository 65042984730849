var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('Header'),_c('div',{staticClass:"mx-auto mt-1 content-outer-container",class:{ 'content-dashboard': _vm.$store.state.isDashboard }},[_c('div',{staticClass:"mx-auto content-inner-container"},[_c('div',{staticClass:"wrap-pdpaUploadHistory"},[_c('div',{staticClass:"row paj-custom-row"},[_c('div',{staticClass:"\n              col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12\n              custom-wrapper-dashboard\n            "},[_c('div',{staticClass:"d-flex align-items-center flex-wrap mr-2 text-title"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/privacy/Key.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.lbl['permission-type-privacy'])+" ")])]),_c('div',{staticClass:"\n              col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12\n              custom-wrapper-dashboard\n            "}),_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12 wrapper-content-tab"},[_c('div',{staticClass:"d-flex wrapper-select-header"},[_c('div',{staticClass:"select-header",class:{ active: false },on:{"click":function($event){return _vm.goto('PdpaUpload')}}},[_vm._v(" "+_vm._s(_vm.lbl['pdpa-upload-menu'])+" ")]),_c('div',{staticClass:"select-header select-hd-right",class:{ active: true }},[_vm._v(" "+_vm._s(_vm.lbl['pdpa-upload-log-menu'])+" ")])])])]),_c('div',{staticClass:"mt-1 mb-3 px-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n                col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12\n                border-right-gray\n              "},[_c('div',{staticClass:"row text-center mb-3"},[_c('div',{staticClass:"col-12 mx-3"},[_c('h4',[_vm._v(_vm._s(_vm.lbl['pdpa-term-header']))])])]),_c('div',{staticClass:"wrap-table"},[_c('a-table',{attrs:{"row-key":"RowKey","columns":_vm.columnsTerm,"data-source":_vm.dataTerm,"pagination":{
                    showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
                    pageSizeOptions: _vm.pageSizeOptions,
                    pageSize: _vm.defaultPageSize,
                    defaultCurrent: _vm.currentTerm,
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    onChange: _vm.onChangeTermPage,
                  }},scopedSlots:_vm._u([{key:"date",fn:function(text){return [_vm._v(" "+_vm._s(_vm.dateShortWithTimeFormat(text))+" ")]}},{key:"thTerm",fn:function(text){return [_c('button',{staticClass:"borderlest",on:{"click":function($event){return _vm.openAModalFile(text)}}},[_vm._v(" "+_vm._s(_vm.lbl['manage-usr-view'])+" ")])]}},{key:"enTerm",fn:function(text){return [_c('button',{staticClass:"borderlest",on:{"click":function($event){return _vm.openAModalFile(text)}}},[_vm._v(" "+_vm._s(_vm.lbl['manage-usr-view'])+" ")])]}}])})],1)]),_c('div',{staticClass:"\n                col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12\n                border-right-gray\n              "},[_c('div',{staticClass:"row text-center mb-3"},[_c('div',{staticClass:"col-12 mx-3"},[_c('h4',[_vm._v(_vm._s(_vm.lbl['pdpa-privacy-header']))])])]),_c('div',{staticClass:"wrap-table"},[_c('a-table',{attrs:{"row-key":"RowKey","columns":_vm.columnsPrivacy,"data-source":_vm.dataPrivacy,"pagination":{
                    showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
                    pageSizeOptions: _vm.pageSizeOptions,
                    pageSize: _vm.defaultPageSize,
                    defaultCurrent: _vm.currentPrivacy,
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    onChange: _vm.onChangePrivacyPage,
                  }},scopedSlots:_vm._u([{key:"date",fn:function(text){return [_vm._v(" "+_vm._s(_vm.dateShortWithTimeFormat(text))+" ")]}},{key:"thPrivacy",fn:function(text){return [_c('button',{staticClass:"borderlest",on:{"click":function($event){return _vm.openAModalFile(text)}}},[_vm._v(" "+_vm._s(_vm.lbl['manage-usr-view'])+" ")])]}},{key:"enPrivacy",fn:function(text){return [_c('button',{staticClass:"borderlest",on:{"click":function($event){return _vm.openAModalFile(text)}}},[_vm._v(" "+_vm._s(_vm.lbl['manage-usr-view'])+" ")])]}}])})],1)]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('div',{staticClass:"row text-center mb-3"},[_c('div',{staticClass:"col-12 mx-3"},[_c('h4',[_vm._v(_vm._s(_vm.lbl['marketing-message-header']))])])]),_c('div',{staticClass:"wrap-table"},[_c('a-table',{attrs:{"row-key":"RowKey","columns":_vm.columnsMarketing,"data-source":_vm.dataMarketing,"pagination":{
                    showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
                    pageSizeOptions: _vm.pageSizeOptions,
                    pageSize: _vm.defaultPageSize,
                    defaultCurrent: _vm.currentMarketing,
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    onChange: _vm.onChangeMarketingPage,
                  }},scopedSlots:_vm._u([{key:"date",fn:function(text){return [_vm._v(" "+_vm._s(_vm.dateShortWithTimeFormat(text))+" ")]}},{key:"thMarketing",fn:function(text){return [_c('button',{staticClass:"borderlest",on:{"click":function($event){return _vm.openAModalFile(text)}}},[_vm._v(" "+_vm._s(_vm.lbl['manage-usr-view'])+" ")])]}},{key:"enMarketing",fn:function(text){return [_c('button',{staticClass:"borderlest",on:{"click":function($event){return _vm.openAModalFile(text)}}},[_vm._v(" "+_vm._s(_vm.lbl['manage-usr-view'])+" ")])]}}])})],1)])])]),_c('a-modal',{staticClass:"h-100 w-100",class:[_vm.currentLocale],attrs:{"footer":null,"centered":""},model:{value:(_vm.visiblePdfModal),callback:function ($$v) {_vm.visiblePdfModal=$$v},expression:"visiblePdfModal"}},[_c('div',{staticClass:"w-100 h-100 my-5"},[_c('iframe',{attrs:{"width":"100%","height":"500px;","frameborder":"0","scrolling":"no","allowtransparency":"true","src":_vm.pdfFileUrl}})])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }