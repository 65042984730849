<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-pdpaUploadHistory">
          <div class="row paj-custom-row">
            <div
              class="
                col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12
                custom-wrapper-dashboard
              "
            >
              <div class="d-flex align-items-center flex-wrap mr-2 text-title">
                <img
                  src="@/assets/images/privacy/Key.svg"
                  alt=""
                  class="mr-2"
                />
                {{ lbl['permission-type-privacy'] }}
              </div>
            </div>
            <div
              class="
                col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12
                custom-wrapper-dashboard
              "
            ></div>

            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-content-tab">
              <div class="d-flex wrapper-select-header">
                <div
                  class="select-header"
                  :class="{ active: false }"
                  @click="goto('PdpaUpload')"
                >
                  {{ lbl['pdpa-upload-menu'] }}
                </div>
                <div
                  class="select-header select-hd-right"
                  :class="{ active: true }"
                >
                  {{ lbl['pdpa-upload-log-menu'] }}
                </div>
              </div>
            </div>
          </div>

          <div class="mt-1 mb-3 px-3">
            <div class="row">
              <div
                class="
                  col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                  border-right-gray
                "
              >
                <div class="row text-center mb-3">
                  <div class="col-12 mx-3">
                    <h4>{{ lbl['pdpa-term-header'] }}</h4>
                  </div>
                </div>
                <div class="wrap-table">
                  <a-table
                    row-key="RowKey"
                    :columns="columnsTerm"
                    :data-source="dataTerm"
                    :pagination="{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                      pageSizeOptions: pageSizeOptions,
                      pageSize: defaultPageSize,
                      defaultCurrent: currentTerm,
                      showSizeChanger: true,
                      hideOnSinglePage: false,
                      onChange: onChangeTermPage,
                    }"
                  >
                    <template slot="date" slot-scope="text">
                      {{ dateShortWithTimeFormat(text) }}
                    </template>
                    <template slot="thTerm" slot-scope="text">
                      <button class="borderlest" @click="openAModalFile(text)">
                        {{ lbl['manage-usr-view'] }}
                      </button>
                    </template>
                    <template slot="enTerm" slot-scope="text">
                      <button class="borderlest" @click="openAModalFile(text)">
                        {{ lbl['manage-usr-view'] }}
                      </button>
                    </template>
                  </a-table>
                </div>
              </div>
              <div
                class="
                  col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                  border-right-gray
                "
              >
                <div class="row text-center mb-3">
                  <div class="col-12 mx-3">
                    <h4>{{ lbl['pdpa-privacy-header'] }}</h4>
                  </div>
                </div>
                <div class="wrap-table">
                  <a-table
                    row-key="RowKey"
                    :columns="columnsPrivacy"
                    :data-source="dataPrivacy"
                    :pagination="{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                      pageSizeOptions: pageSizeOptions,
                      pageSize: defaultPageSize,
                      defaultCurrent: currentPrivacy,
                      showSizeChanger: true,
                      hideOnSinglePage: false,
                      onChange: onChangePrivacyPage,
                    }"
                  >
                    <template slot="date" slot-scope="text">
                      {{ dateShortWithTimeFormat(text) }}
                    </template>
                    <template slot="thPrivacy" slot-scope="text">
                      <button class="borderlest" @click="openAModalFile(text)">
                        {{ lbl['manage-usr-view'] }}
                      </button>
                    </template>
                    <template slot="enPrivacy" slot-scope="text">
                      <button class="borderlest" @click="openAModalFile(text)">
                        {{ lbl['manage-usr-view'] }}
                      </button>
                    </template>
                  </a-table>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="row text-center mb-3">
                  <div class="col-12 mx-3">
                    <h4>{{ lbl['marketing-message-header'] }}</h4>
                  </div>
                </div>
                <div class="wrap-table">
                  <a-table
                    row-key="RowKey"
                    :columns="columnsMarketing"
                    :data-source="dataMarketing"
                    :pagination="{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                      pageSizeOptions: pageSizeOptions,
                      pageSize: defaultPageSize,
                      defaultCurrent: currentMarketing,
                      showSizeChanger: true,
                      hideOnSinglePage: false,
                      onChange: onChangeMarketingPage,
                    }"
                  >
                    <template slot="date" slot-scope="text">
                      {{ dateShortWithTimeFormat(text) }}
                    </template>
                    <template slot="thMarketing" slot-scope="text">
                      <button class="borderlest" @click="openAModalFile(text)">
                        {{ lbl['manage-usr-view'] }}
                      </button>
                    </template>
                    <template slot="enMarketing" slot-scope="text">
                      <button class="borderlest" @click="openAModalFile(text)">
                        {{ lbl['manage-usr-view'] }}
                      </button>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
          <a-modal
            v-model="visiblePdfModal"
            :footer="null"
            centered
            class="h-100 w-100"
            :class="[currentLocale]"
          >
            <div class="w-100 h-100 my-5">
              <iframe
                width="100%"
                height="500px;"
                frameborder="0"
                scrolling="no"
                allowtransparency="true"
                :src="pdfFileUrl"
              ></iframe>
            </div>
          </a-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsPdpa from '@/core/Settings/BzbsSettingsPdpa'
import moment from 'moment'
import Locale from '@/helper/locale.js'
import Header from '@/components/Layout/Header'
import Vue from 'vue'

var localText = 'TH'

const columnsTerm = [
  {
    title: 'Uploaded Date',
    dataIndex: 'ImportDate',
    key: 'pdpa-log-upd-date-header',
    align: 'center',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: localText,
    dataIndex: 'ThaiFileUrl',
    key: 'pdpa-log-th-header',
    align: 'center',
    class: 'ant-table-file-header',
    ellipsis: true,
    scopedSlots: {
      customRender: 'thTerm',
    },
  },
  {
    title: 'EN',
    key: 'pdpa-log-en-header',
    dataIndex: 'EnglishFileUrl',
    align: 'center',
    class: 'ant-table-file-header',
    ellipsis: true,
    scopedSlots: {
      customRender: 'enTerm',
    },
  },
]

const columnsPrivacy = [
  {
    title: 'Uploaded Date',
    dataIndex: 'ImportDate',
    key: 'pdpa-log-upd-date-header',
    align: 'center',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: localText,
    dataIndex: 'ThaiFileUrl',
    key: 'pdpa-log-th-header',
    align: 'center',
    class: 'ant-table-file-header',
    ellipsis: true,
    scopedSlots: {
      customRender: 'thPrivacy',
    },
  },
  {
    title: 'EN',
    key: 'pdpa-log-en-header',
    dataIndex: 'EnglishFileUrl',
    align: 'center',
    class: 'ant-table-file-header',
    ellipsis: true,
    scopedSlots: {
      customRender: 'enPrivacy',
    },
  },
]

const columnsMarketing = [
  {
    title: 'Uploaded Date',
    dataIndex: 'ImportDate',
    key: 'pdpa-log-upd-date-header',
    align: 'center',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: localText,
    dataIndex: 'ThaiFileUrl',
    key: 'pdpa-log-th-header',
    align: 'center',
    class: 'ant-table-file-header',
    ellipsis: true,
    scopedSlots: {
      customRender: 'thMarketing',
    },
  },
  {
    title: 'EN',
    key: 'pdpa-log-en-header',
    dataIndex: 'EnglishFileUrl',
    align: 'center',
    class: 'ant-table-file-header',
    ellipsis: true,
    scopedSlots: {
      customRender: 'enMarketing',
    },
  },
]

export default {
  name: 'PdpaUploadHistory',
  mixins: [Mixin],
  components: {
    Header,
  },
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      isConsent: false,
      columnsTerm,
      dataTerm: [],
      totalTerm: null,
      currentTerm: 1,
      columnsPrivacy,
      dataPrivacy: [],
      totalPrivacy: null,
      currentPrivacy: 1,
      columnsMarketing,
      dataMarketing: [],
      totalMarketing: null,
      currentMarketing: 1,
      visiblePdfModal: false,
      pdfFileUrl: '',
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
    }
  },
  beforeCreate() {},
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'privacy_conditions_page',
      'privacy_conditions',
      'view_condition_history',
      'on view',
    )
    this.getTerm(0)
    this.getPrivacy(0)
    this.getMarketing(0)
    this.setLocale()
    this.getCRMPlusProfile().then(result => {
      // Modify by Achira on 202110061742 edit by u-199288
      this.isConsent = result.Ispdpa
    })
    if (Vue.bzbsConfig.businessRule.webFor.PH) localText = 'PH'
  },
  methods: {
    setLocale() {
      _.forEach(this.columnsTerm, column => {
        column.title = this.lbl[column.key]
      })
      _.forEach(this.columnsPrivacy, column => {
        column.title = this.lbl[column.key]
      })
      _.forEach(this.columnsMarketing, column => {
        column.title = this.lbl[column.key]
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    changePdpaConsent() {
      this.handleLoading(true)
      BzbsPdpa.postPdpaConsent(this.isConsent)
        .then(res => {
          this.handleLoading(false)
          console.log(res)
        })
        .catch(err => {
          this.handleLoading(false)
          console.log(err)
        })
    },
    onChangeTermPage(page) {
      this.currentTerm = page
      this.getPdpaUploadLog('terms', 10, 10 * (page - 1))
    },
    onChangePrivacyPage(page) {
      this.currentPrivacy = page
      this.getPdpaUploadLog('privacy', 10, 10 * (page - 1))
    },
    getPdpaUploadLog(type, top, skip) {
      this.handleLoading(true)

      return new Promise(resolve => {
        BzbsPdpa.getImportConsentLog(type, top, skip)
          .then(res => {
            this.handleLoading(false)
            if (type == 'terms') {
              this.totalTerm = res.data.total == 0 ? 1 : res.data.total
              this.dataTerm = res.data.data
            }
            if (type == 'privacy') {
              this.totalPrivacy = res.data.total == 0 ? 1 : res.data.total
              this.dataPrivacy = res.data.data
            }
            if (type == 'marketing') {
              console.log('total', res.data.total)
              this.totalMarketing = res.data.total == 0 ? 1 : res.data.total
              this.dataMarketing = res.data.data
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiImportConsentLog error', error)
            resolve(error)
          })
      })
    },
    getTerm(skip) {
      if (!skip) skip = 0
      this.getPdpaUploadLog('terms', 10, skip)
    },
    getPrivacy(skip) {
      if (!skip) skip = 0
      this.getPdpaUploadLog('privacy', 10, skip)
    },
    dateShortWithTimeFormat(timestamp) {
      moment.locale(Locale.getLocaleCode())
      var strDateTime = moment.unix(timestamp).utc().format('DD-MMM-YYYY HH:mm')
      return strDateTime
    },
    openAModalFile(url) {
      this.visiblePdfModal = true
      this.pdfFileUrl = url
      console.log(url)
    },

    // Marketing Message
    getMarketing(skip) {
      if (!skip) skip = 0
      this.getPdpaUploadLog('marketing', 10, skip)
    },
    onChangeMarketingPage(page) {
      this.currentMarketing = page
      this.getPdpaUploadLog(10, 10 * (page - 1))
    },
  },
}
</script>

<style lang="scss">
@import 'style/PdpaUploadHistory.scss';
</style>
